import { configureStore, combineReducers } from '@reduxjs/toolkit'
// Or from '@reduxjs/toolkit/query/react'
import { setupListeners } from '@reduxjs/toolkit/query'
import { products } from './Services/products'
import productReducer from './Reducers/productSlice'

import storage from 'redux-persist/lib/storage';
import {persistReducer, persistStore} from 'redux-persist';
import surveyReducer from "./Reducers/surveySlice";


const persistConfig = {
    key: 'root',
    storage,
    blacklist: ['products'],
};

const rootReducer = combineReducers({
    [products.reducerPath]: products.reducer,
    productsData: productReducer,
    survey: surveyReducer,
});


const persistedReducer = persistReducer(persistConfig, rootReducer);

export const store = configureStore({
    reducer: persistedReducer,
    // Adding the api middleware enables caching, invalidation, polling,
    // and other useful features of `rtk-query`.
    middleware: (getDefaultMiddleware) =>
        getDefaultMiddleware().concat(products.middleware),
})

export const persistor = persistStore(store);
// optional, but required for refetchOnFocus/refetchOnReconnect behaviors
// see `setupListeners` docs - takes an optional callback as the 2nd arg for customization
setupListeners(store.dispatch)