import { createSlice } from '@reduxjs/toolkit'
import {products} from "../Services/products";

const initialState = {
    lang: 'en', // 'en' or 'ar
    value: 0,
    products: null,
    cartProducts: [],
    subTotal: 0,
    discount: 0,
    tax: 0,
    total: 0,
    qty: 0,
    productId: []
}

export const productSlice = createSlice({
    name: 'product',
    initialState,
    reducers: {
        increment: (state, { payload }) => {
            state.products  = state.products.map((item, index) => {
                if (item.id === payload.id) {
                    return {
                        ...item,
                        qty : item.qty + 1,
                    }
                }
                return item
            })
        },
        decrement: (state, {payload}) => {
            state.products  = state.products.map((item, index) => {
                if (item.id === payload.id) {
                    return {
                        ...item,
                        qty : item.qty - 1,
                    }
                }
                return item
            })
        },
        addToCart: (state, {payload}) => {
            const products = state.products.find((item) =>  item.id === payload?.id)
            const cartProducts = state.cartProducts.find((item) =>  item.id === payload?.id)
            if (products && products.qty > 0 && !state.cartProducts.includes(cartProducts)) {
                state.cartProducts.push(products)
            } else if(products && products.qty > 0 && state.cartProducts.includes(cartProducts)) {
                state.cartProducts = state.cartProducts.map((item, index) => {
                    if (item.id === payload.id) {
                        return {
                            ...item,
                            qty : products.qty,
                        }
                    }
                    return item
                })
            }
            if(products.qty === 0){
                state.cartProducts = state.cartProducts.filter((item) => item.id !== payload?.id)
            }
            let subtotal = 0, tax = 0, qty = 0, productId = []
            state.cartProducts.map((item, index) => {
                subtotal += parseFloat(item.qty) * parseFloat(item.price)
                tax = parseFloat(subtotal) * 0.15
                qty += item.qty
                productId.push(item.id)
            })
            state.subTotal = parseFloat(subtotal).toFixed(2)
            state.tax = parseFloat(tax).toFixed(2)
            let total = parseFloat(subtotal) + parseFloat(tax)
            state.total = parseFloat(total).toFixed(2)
            state.qty = qty
            state.productId = productId

        },
        removeCart: (state, { payload }) => {
            const products = state.products.find((item) =>  item.id === payload?.id)
            const cartProducts = state.cartProducts.find((item) =>  item.id === payload?.id)
            if (products.qty === 0) {
                state.cartProducts = state.cartProducts.filter((item) => item.id !== payload?.id)
            } else {
                state.cartProducts = state.cartProducts.map((item, index) => {
                    if (item.id === payload.id) {
                        return {
                            ...item,
                            qty : item.qty + 1,
                        }
                    }
                    return item
                })
            }

        },
        changeLang: (state, { payload }) => {
            state.lang = payload
        },

    },
    extraReducers: (builder) => {
        builder
            .addMatcher(
                products.endpoints.getProducts.matchFulfilled, (state, { payload }) => {
                    let data = payload.data
                    state.products = data.map((item, index) => {
                        return {
                            ...item,
                            qty : 0,
                            price: 520.87,
                        }
                    })
                    state.qty = 0
                    state.discount = 0
                    state.productId = []
                    state.cartProducts = []
                    state.total = 0
                    state.lang = 'en'
                }
            )
            .addMatcher(
                products.endpoints.addCouponCode.matchFulfilled, (state, { payload }) => {
                    let data = payload
                    if(data?.status === 200){
                        let total = parseFloat(state?.subTotal) - parseFloat(data?.subtotal) + parseFloat(state.tax)
                        state.total = parseFloat(total).toFixed(2)
                        state.discount = data?.d_percent
                    }
                }
            )

    },
})

// Action creators are generated for each case reducer function
export const { increment, decrement , addToCart, changeLang} = productSlice.actions

export default productSlice.reducer