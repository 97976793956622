import AppRouting from './Routing/AppRouting';
import { Toaster } from 'react-hot-toast';

function App() {
    return (
        <>
            <Toaster
                position="top-right"
                reverseOrder={false}
            />
            <AppRouting/>
        </>
    );
}

export default App;
