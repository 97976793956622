import React, {lazy} from "react";
import { BrowserRouter, Route, Routes } from "react-router-dom";

const Layout = lazy(() => import("../Pages/Layout"));
const Home = lazy(() => import("../Pages/Home"));
const Designer = lazy(() => import("../Pages/Designer"));
const Projects = lazy(() => import("../Pages/Projects"));
const ProjectDetail = lazy(() => import("../Pages/ProjectDetail"));
const DesignerDetail = lazy(() => import("../Pages/DesignerDetail"));
const BlogDetail = lazy(() => import("../Pages/BlogDetail"));
const Blog = lazy(() => import("../Pages/Blog"));
const PrivacyPolicy = lazy(() => import("../Pages/PrivacyPolicy"));
const ServicePolicy = lazy(() => import("../Pages/ServicePolicy"));
const Products = lazy(() => import("../Pages/Products"));
const OurStory = lazy(() => import("../Pages/OurStory"));
const Checkout = lazy(() => import("../Pages/Checkout"));
const Job = lazy(() => import("../Pages/Job"));
const Package = lazy(() => import("../Components/Package/Package"));
const JobDetail = lazy(() => import("../Pages/JobDetail"));
const HelpCenter = lazy(() => import("../Pages/HelpCenter"));
const Survey = lazy(() => import("../Pages/Survey"));


const AppRouting = () => {
  return (
    <>
      <BrowserRouter>
        <Routes>
          <Route path="/" element={<Layout />}>
            <Route index element={<Home />} />
            <Route path="designers">
              <Route index element={<Designer />} />
              <Route path=":id" element={<DesignerDetail />} />
            </Route>
            <Route path="projects">
              <Route index element={<Projects />} />
              <Route path=":id" element={<ProjectDetail />} />
            </Route>
            <Route path="products" element={<Products />} />
            <Route path="checkout" element={<Checkout />} />

            <Route path="blog" element={<Blog />} />
            <Route path="privacy-policy" element={<PrivacyPolicy />} />
            <Route path="service-policy" element={<ServicePolicy />} />
            <Route path="blog-detail" element={<BlogDetail />} />
            <Route path="our-story" element={<OurStory />} />
            <Route path="jobs" element={<Job />} />
            <Route path="package" element={<Package />} />
            <Route path="job-detail" element={<JobDetail />} />
            <Route path="help-center" element={<HelpCenter />} />
            <Route path="survey" element={<Survey />} />
          </Route>
        </Routes>
      </BrowserRouter>
    </>
  );
};

export default AppRouting;
