import { createSlice } from '@reduxjs/toolkit'
import {products} from "../Services/products";

const initialState = {
    surveyForm: {
        prefer_minimalist: null,
        email: null,
        about_hear_id: null,
        surveyResult: null
    },
}

export const surveySlice = createSlice({
    name: 'survey',
    initialState,
    reducers: {
        setSurveyResult: (state, { payload }) => {
            state.surveyForm  = Object.assign({}, state.surveyForm, {surveyResult: payload });
        },
        setPreferMinimalist: (state, { payload }) => {
            state.surveyForm  = Object.assign({}, state.surveyForm, {prefer_minimalist: payload });
        },
        setSurveyEmailAboutFina: (state, { payload }) => {
            state.surveyForm  = Object.assign({}, state.surveyForm, {email: payload?.email, about_hear_id: payload?.about_hear_id });
        },
        removeSurveyData: (state, {payload}) => {
            state.surveyForm = Object.assign({}, state.surveyForm, {surveyResult: null, prefer_minimalist: null, email: null, about_hear_id: null, });
        }
    },
})

// Action creators are generated for each case reducer function
export const { setSurveyResult, setPreferMinimalist, setSurveyEmailAboutFina, removeSurveyData } = surveySlice.actions

export default surveySlice.reducer