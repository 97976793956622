import React from 'react';
import {Spinner} from "react-bootstrap";

const GlobalLoader = () => {
    return (
        <>
            <div className="w-100 d-flex align-items-center justify-content-center" style={{height: '100dvh'}}>
                <div className="col-auto">
                    <Spinner animation="border" variant="primary" role="status" className="m-auto d-block"/>
                </div>
            </div>
        </>
    );
};

export default GlobalLoader;